import React, { Component } from "react";
import { connect } from "react-redux";
import {
  listarServicos,
  deletarServico,
  importarTuss,
} from "../../../../api/servicos";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Notificacao from "../../../notificacao/Notificacao";
import Button from "@material-ui/core/Button";

import TableComponent from "../../../table/TableComponent";

//Icone de arquivar
import Edit from "@material-ui/icons/Edit";
import FileCopy from "@material-ui/icons/FileCopy";

class ServicosConfigServico extends Component {
  constructor(props) {
    super(props);
    this.importaTuss = this.importaTuss.bind(this);
  }
  state = {
    servicos: [],
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
  };

  deletaServico(objeto) {
    const { clinica } = this.props;
    deletarServico(objeto, clinica._id);
  }

  importaTuss(clinic) {
    importarTuss(clinic)
      .then((r) => {
        if (r) {
          this.setState({
            alerta: {
              tipo: "success",
              msg: "Serviços TUSS importado com sucesso!",
              abrir: true,
            },
          });
        } else {
          this.setState({
            alerta: {
              tipo: "error",
              msg: "Infelizmente importar os serviços TUSS!",
              abrir: true,
            },
          });
        }
      })
      .then(this.setState({ alerta: { abrir: false } }));
  }

  componentWillMount() {
    listarServicos(this.props.clinica._id)
      .then((arrServicos) => {
        var listaServicos = [];

        arrServicos.length > 0 &&
          arrServicos.map((servico, index) => {
            var novoServico = {};

            novoServico.descricao = servico.descricao;
            novoServico.preco = servico.preco.toFixed(2);
            novoServico.ativo = servico.ativo ? "Ativo" : "Inativo";
            novoServico._id = servico._id;
            novoServico.funcoes = [
              <Tooltip title={"Editar Serviço"} key={index}>
                <IconButton
                  onClick={() =>
                    this.props.history.push(
                      `/servicos/config/cadastrarServico/${servico._id}`,
                    )
                  }
                >
                  <Edit />
                </IconButton>
              </Tooltip>,
              <Tooltip title={"Copiar Serviço"} key={servico._id}>
                <IconButton
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/servicos/config/cadastrarServico",
                      state: {
                        servico,
                      },
                    })
                  }
                >
                  <FileCopy />
                </IconButton>
              </Tooltip>,
            ];

            listaServicos.push(novoServico);
          });

        this.setState({ servicos: listaServicos });
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    const titulo = "Servico",
      colunas = [
        { label: "Serviço", name: "descricao" },
        { label: "Preço base", name: "preco" },
        { label: "Ativo", name: "ativo" },
        { label: " ", name: "funcoes", options: { filter: false, sort: false } },
      ],
      objetos = this.state.servicos,
      inserir = {
        nome: "Cadastrar Serviço",
        link: "cadastrarServico",
      };

    return (
      <div>
        {this.state.alerta.abrir && (
          <Notificacao
            tipo={this.state.alerta.tipo}
            msg={this.state.alerta.msg}
          />
        )}
        <Button
          style={{ backgroundColor: "#f1f1f1", margin: "5px" }}
          onClick={() => this.importaTuss(this.props.clinica._id)}
        >
          Importar TUSS
        </Button>
        <TableComponent
          funcoes={{ deletar: this.deletaServico.bind(this) }}
          titulo={titulo}
          inserir={inserir}
          colunas={colunas}
          objetos={objetos}
          {...this.props}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(ServicosConfigServico);
