import React, { Component } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { editPatient } from "../../../../actions/patientsSearch";
import { getAllPatients } from "../../../../actions/patientsCreation";

import TableComponent from "../../../table/TableComponent";

import moment from "moment";
import {
  Archive,
  Visibility,
  CalendarToday as CalendarTodayIcon,
} from "@material-ui/icons";

class PacientesListagem extends Component {
  state = {
    pacientes: [],
  };

  componentWillMount() {
    const { tab, trocarTab, getAllPatients } = this.props;

    const tabSelecionada = tab.lista.filter((tab) => tab.url === "listagem")[0];

    if (tabSelecionada.numero !== tab.tabAtiva) {
      trocarTab(tabSelecionada.numero);
    }

    getAllPatients(this.props.clinica._id);
  }

  UNSAFE_componentWillReceiveProps(props) {
    var { pacientes } = props;

    if (!pacientes) pacientes = [];

    var listaPacientes = [];

    pacientes.length > 0 &&
      pacientes.map((paciente) => {
        if (paciente.active) {
          var novoPaciente = {};

          novoPaciente.registro = paciente.registry;
          novoPaciente.paciente = `${paciente.name} ${paciente.lastName}`;
          novoPaciente.email = paciente.email || "";
          novoPaciente.nascimento =
            paciente.birthday !== null &&
            moment(paciente.birthday).format("DD/MM/YYYY");
          novoPaciente.telefone =
            paciente.telephones.length > 0
              ? paciente.telephones.filter(
                  (telefone) => telefone.name === "Principal"
                )[0]?.value
              : "";
          novoPaciente.fromSchedule = paciente.fromSchedule ? "Sim" : "Não";
          novoPaciente.funcoes = [
            <>
              <Tooltip key={paciente.registry} title={"Visualizar Cadastro"}>
                <IconButton
                  onClick={() =>
                    this.props.history.push(`/patients/profile/${paciente._id}`)
                  }
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
              <Tooltip key={paciente.name} title={"Arquivar"}>
                <IconButton
                  onClick={() => {
                    this.props
                      .editPatient({
                        id: paciente._id,
                        props: { active: false },
                        message: `paciente ${paciente.name} arquivado com sucesso!`,
                      })
                      .then((r) =>
                        this.props.getAllPatients(this.props.clinica._id)
                      );
                  }}
                >
                  <Archive />
                </IconButton>
              </Tooltip>
            </>,
          ];

          listaPacientes.push(novoPaciente);
        }
      });

    this.setState({ pacientes: listaPacientes });
  }

  render() {
    const titulo = "Pacientes Ativos",
      colunas = [
        { label: "Registro", name: "registro" },
        { label: "Paciente", name: "paciente" },
        { label: "Email", name: "email" },
        { label: "Telefone", name: "telefone" },
        { label: "Data Nascimento", name: "nascimento" },
        {
          label: "Agenda",
          name: "fromSchedule",
          options: {
            customBodyRender: (value) => {
              return value === "Sim" ? (
                <Tooltip title="Criado pela agenda">
                  <CalendarTodayIcon
                    style={{ color: this.state.hovered ? "red" : "inherit" }}
                    onMouseEnter={() => this.setState({ hovered: true })}
                    onMouseLeave={() => this.setState({ hovered: false })}
                  />
                </Tooltip>
              ) : (
                ""
              );
            },
          },
        },
        {
          label: " ",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = this.state.pacientes;
    return (
      <TableComponent
        selecionarLinhas={"none"}
        titulo={titulo}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth, patientsCreation } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
    pacientes: patientsCreation.listPatients,
  };
}
export default connect(mapStateToProps, { editPatient, getAllPatients })(
  PacientesListagem
);
