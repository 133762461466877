import React, { Component } from "react";
import fs from "fs";
import { connect } from "react-redux";
import axios from "axios";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import ReactModal from "react-modal";
import { css } from "aphrodite/no-important";
import { styles } from "./ModalStyles";
import Modal from "../modals/Modal";
import Button from "../common/Button";
import {
  alterarPropsMedidaImage,
  buscarLaudo,
  buscarPropsImage,
  salvarLaudo,
} from "../../api/pacientes";
import { buscarLaudoPadrao, atualizarLaudoPadrao } from "../../api/clientes";
import { showMessageSystem } from "../../actions/systemMsg";
import Notificacao from "../notificacao/Notificacao";
import { sendEmail } from "../../actions/email";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  DialogTitle,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import ButtonMUI from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import FroalaEditor from "react-froala-wysiwyg";
require("froala-editor/js/plugins.pkgd.min.js");
require("froala-editor/js/plugins/align.min.js");
require("react-froala-wysiwyg/FroalaEditorButton");
require("react-froala-wysiwyg/FroalaEditorImg");
require("react-froala-wysiwyg/FroalaEditorInput");
require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/css/froala_style.min.css");
require("font-awesome/css/font-awesome.css");

const icona = require("tui-image-editor/dist/svg/icon-a.svg");
const iconb = require("tui-image-editor/dist/svg/icon-b.svg");
const iconc = require("tui-image-editor/dist/svg/icon-c.svg");
const icond = require("tui-image-editor/dist/svg/icon-d.svg");

var blackTheme = {
  // or white
  // load button
  "loadButton.display": "none",
  "downloadButton.display": "none",
  "common.bisize.width": "0",

  "menu.normalIcon.path": icond,
  "menu.activeIcon.path": iconb,
  "menu.disabledIcon.path": icona,
  "menu.hoverIcon.path": iconc,
};

class MyComponent extends Component {
  state = {
    milimeter: "",
    mouseMove: false,
    scale: 0,
    dialogMilimeter: false,
    dialogPontoA: false,
    dialogPontoB: false,
    modalLaudo: false,
    model: "",
    modelTemp: "",
    modelPadrao: "",
    modelPadraoTemp: "",
    modelAtivo: "model",
    dialogEmail: false,
    loading: false,
    warningDialog: {
      context: "",
      open: false,
    },
    btnPadrao: "initial",
    alerta: {
      tipo: "",
      msg: "",
      abrir: false,
    },
  };

  image = {
    selecionarDpi: false,
    startPoint: { x: 0, y: 0 },
    endPoint: { x: 0, y: 0 },
  };

  componentWillMount() {
    const fetchData = async () => {
      const laudo = await buscarLaudoPadrao();
      const propsImage = await buscarPropsImage({
        patient_id: this.props.patient._id,
        hash: this.props.hash,
      });
      this.setState({
        ...this.state,
        modelPadrao: laudo,
        modelPadraoTemp: laudo,
        startPoint: propsImage.gallery.measure?.startPoint ?? 0,
        endPoint: propsImage.gallery.measure?.endPoint ?? 0,
        milimeter: propsImage.gallery.measure?.milimeter ?? 0,
        scale: propsImage.gallery.measure?.scale ?? 0,
      });
    };
    fetchData();
  }

  componentWillUnmount() {}

  abrirLaudo = () => {
    buscarLaudo(this.props.patient._id, this.props.titulo)
      .then((laudo) => {
        this.setState({ model: laudo.report, modelTemp: laudo.report });
      })
      .catch(this.setState({ model: "", modelTemp: "" }));

    this.setState({ modalLaudo: !this.state.modalLaudo });
  };

  salvar = () => {
    if (this.state.modelAtivo === "modelPadrao") {
      atualizarLaudoPadrao(this.state.modelPadrao)
        .then((status) => {
          if (status === 200) {
            this.setState({
              alerta: {
                tipo: "success",
                msg: "Padrão atualizado com sucesso!",
                abrir: true,
              },
              modelAtivo: "model",
              modelPadraoTemp: this.state.modelPadrao,
            });
          } else {
            this.setState({
              alerta: {
                tipo: "error",
                msg: "Padrão não atualizado!",
                abrir: true,
              },
              modelAtivo: "model",
            });
          }
        })
        .then(this.setState({ alerta: { abrir: false } }));
    } else {
      salvarLaudo(this.props.patient._id, this.props.titulo, this.state.model)
        .then((status) => {
          if (status === 200) {
            this.setState({
              alerta: {
                tipo: "success",
                msg: "Laudo atualizado com sucesso!",
                abrir: true,
              },
            });
          } else {
            this.setState({
              alerta: {
                tipo: "error",
                msg: "Laudo não atualizado!",
                abrir: true,
              },
            });
          }
        })
        .then(this.setState({ alerta: { abrir: false } }));

      this.setState({ modelTemp: this.state.model, modalLaudo: false });
    }
  };

  editorRef = React.createRef();

  handleClickButton = async () => {
    const editorInstance = this.editorRef.current.getInstance();
    let base = editorInstance.toDataURL();

    let retorno = await axios
      .post("/api/updateImage", {
        path: this.props.path,
        base64: base,
        dir: this.props.dir,
      })
      .then((response) => {
        if (response.status === 200) return response.data;
      })
      .catch((error) => console.log(error));

    if (retorno === "OK") {
      const objectSave = {
        patient_id: this.props.patient._id,
        hash: this.props.hash,
        measure: {
          startPoint: this.image.startPoint,
          endPoint: this.image.endPoint,
          milimeter: this.state.milimeter,
          scale: this.state.scale,
        },
      };
      await alterarPropsMedidaImage(objectSave);
      this.props.close();
    } else {
      alert("Tente novamente mais tarde!");
    }
  };

  verifySendEmail = () => {
    const email = {
      title: this.props.titulo ? this.props.titulo : "",
      html: this.state.model,
      destiny: this.props.patient.email,
    };

    if (email.title && email.html !== "" && email.destiny) {
      this.setState({ dialogEmail: true });
    } else {
      this.setState({
        warningDialog: {
          open: true,
          context:
            "Verifique se o e-mail possui assunto, corpo e o paciente tem e-mail cadastrado.",
        },
      });
    }
  };

  handleSendEmail = () => {
    const email = {
      title: this.props.titulo,
      html: this.state.model,
      destination: [this.props.patient],
    };

    // seta o componente loading para true
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });
    }

    this.props.sendEmail(email.title, email.html, email.destination, (res) => {
      this.setState({
        dialogEmail: false,
        modalLaudo: false,
        success: true,
        loading: false,
      });
    });
  };

  handleMousedown = (event, { x, y }) => {
    const editorInstance = this.editorRef.current.getInstance();
    if (this.image.selecionarDpi) {
      if (!this.image.startPoint.x && !this.image.startPoint.y) {
        this.image.startPoint.x = x;
        this.image.startPoint.y = y;
        return this.setState({ ...this.state, dialogPontoB: true });
      }
      if (!this.image.endPoint.x && !this.image.endPoint.y) {
        this.image.endPoint.x = x;
        this.image.endPoint.y = y;
        this.image.selecionarDpi = false;
      }
      const pixelDistance = Math.sqrt(
        Math.pow(this.image.endPoint.x - this.image.startPoint.x, 2) +
          Math.pow(this.image.endPoint.y - this.image.startPoint.y, 2)
      );
      const scale = pixelDistance / this.state.milimeter;
      editorInstance.changeCursor("default");
      this.setState({ ...this.state, scale: scale });
    }
    const canvas = document.getElementsByClassName("upper-canvas")[0];
    const context = canvas.getContext("2d");
    var bounds = canvas.getBoundingClientRect();
    if (editorInstance.getDrawingMode() === "LINE_DRAWING") {
      let cX;
      let cY;
      let mm;
      window.addEventListener("mousemove", (e) => {
        if (!this.state.mouseMove) return;
        cX = ((e.clientX - bounds.left) / bounds.width) * canvas.width;
        cY = ((e.clientY - bounds.top) / bounds.height) * canvas.height;
        const positions = {
          startPoint: { x, y },
          endPoint: { x: cX, y: cY },
        };
        mm = this.calculateMilimeterScale(
          positions.startPoint,
          positions.endPoint
        );
        const midX = (x + cX) / 2;
        const midY = (y + cY) / 2;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.font = "20px serif";
        context.fillStyle = "red";
        context.fillText(parseInt(mm) + " mm", midX, midY);
      });
      window.addEventListener("mouseup", (e) => {
        if (!this.state.mouseMove) return;
        context.clearRect(0, 0, canvas.width, canvas.height);
        const midX = (x + cX) / 2;
        const midY = (y + cY) / 2;
        editorInstance.addText(parseInt(mm) + " mm", {
          styles: {
            backgroundColor: "white",
            fill: "red",
            fontSize: "20",
          },
          position: {
            x: midX,
            y: midY,
          },
        });
        editorInstance.stopDrawingMode();
        editorInstance.discardSelection();
        this.setState({ ...this.state, mouseMove: false });
      });
    }
  };

  handleSubmitMillimeter = () => {
    this.image = {
      startPoint: { x: 0, y: 0 },
      endPoint: { x: 0, y: 0 },
      scale: 0,
    };
    this.setState({
      ...this.state,
      dialogMilimeter: false,
      dialogPontoA: true,
    });
  };

  handleSubmitPontoA = () => {
    this.image = {
      ...this.image,
      selecionarDpi: true,
    };
    const editorInstance = this.editorRef.current.getInstance();
    editorInstance.changeCursor("crosshair");
    this.setState({
      ...this.state,
      dialogPontoA: false,
    });
  };

  handleSubmitPontoB = () => {
    this.setState({
      ...this.state,
      dialogPontoB: false,
    });
  };

  handleMeasuring = () => {
    if (!this.state.scale) return;
    const editorInstance = this.editorRef.current.getInstance();
    this.setState({ ...this.state, mouseMove: true });
    editorInstance.startDrawingMode("LINE_DRAWING", {
      width: 2,
      color: "red",
      arrowType: {
        tail: "chevron",
      },
    });
  };

  calculateMilimeterScale = (startPoint, endPoint) => {
    const pixelDistance = Math.sqrt(
      Math.pow(endPoint.x - startPoint.x, 2) +
        Math.pow(endPoint.y - startPoint.y, 2)
    );
    const mmDistance = pixelDistance / this.state.scale;
    return mmDistance;
  };

  render() {
    var btnPadrao = this.state.modelAtivo === "model" ? "initial" : "none";

    return (
      <>
        <Dialog open={this.props.isOpen} fullScreen maxWidth="lg">
          {this.state.alerta.abrir && (
            <Notificacao
              tipo={this.state.alerta.tipo}
              msg={this.state.alerta.msg}
            />
          )}
          <div>
            <ImageEditor
              ref={this.editorRef}
              includeUI={{
                loadImage: {
                  path: this.props.path,
                  name: "nome",
                },
                theme: blackTheme,
                uiSize: {
                  height: "100vh",
                },
                menuBarPosition: "bottom",
              }}
              onMousedown={(event, originPointer) =>
                this.handleMousedown(event, originPointer)
              }
              cssMaxWidth={1900}
              cssMaxHeight={800}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70,
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                cursor: "pointer",
              }}
            >
              <ButtonMUI
                variant="contained"
                size="small"
                onClick={() => this.abrirLaudo()}
              >
                Laudo
              </ButtonMUI>
              <ButtonMUI
                variant="contained"
                size="small"
                style={{ marginLeft: "8px" }}
                onClick={() =>
                  this.setState({ ...this.state, dialogMilimeter: true })
                }
              >
                DEFINIR MEDIDAS
              </ButtonMUI>
            </div>
            <div
              style={{
                position: "absolute",
                top: "90px",
                left: "10px",
                color: "white",
              }}
            >
              Escala: {parseInt(this.state.scale)}x
            </div>
            <div
              style={{
                position: "absolute",
                top: "50px",
                left: "10px",
              }}
            >
              <ButtonMUI
                variant="contained"
                size="small"
                onClick={() => this.handleMeasuring()}
              >
                Medir
              </ButtonMUI>
            </div>
            <div
              style={{
                position: "absolute",
                top: "50px",
                left: "10px",
                display: "inline-flex",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                display: "inline-flex",
              }}
            >
              <ButtonMUI
                variant="contained"
                size="small"
                onClick={this.handleClickButton}
              >
                Salvar imagem
              </ButtonMUI>
              <ButtonMUI
                style={{ marginLeft: "8px" }}
                variant="contained"
                size="small"
                onClick={() => this.props.close()}
              >
                Fechar
              </ButtonMUI>
            </div>
          </div>

          <Dialog open={this.state.modalLaudo} header="Laudo">
            <div style={{ padding: "1rem" }}>
              <Button
                color="primary"
                text={"Colar texto padrão"}
                style={{ display: btnPadrao, marginBottom: ".5rem" }}
                onClick={() =>
                  this.state.modelAtivo === "model" &&
                  this.setState({ model: this.state.modelPadrao })
                }
              />
              <Button
                color="secondary"
                text={"Editar texto padrão"}
                style={{ display: btnPadrao, marginBottom: ".5rem" }}
                onClick={() =>
                  this.state.modelAtivo === "model" &&
                  this.setState({ modelAtivo: "modelPadrao" })
                }
              />
              <Button
                text="Enviar por e-mail"
                color="green2"
                onClick={() => this.verifySendEmail()}
                right
                icon="mail"
              ></Button>
              <TextField
                value={this.props.titulo}
                variant="outlined"
                fullWidth
                disabled
                label="Assunto"
                placeholder="Digite o assunto do e-mail"
                style={{ margin: "12px 0px 12px 0" }}
              />
              <FroalaEditor
                tag="textarea"
                config={{
                  height: "250px",
                  charCounterCount: true,
                  toolbarButtons: [
                    "fontFamily",
                    "fontSize",
                    "textColor",
                    "bold",
                    "italic",
                    "underline",
                    "strikeThrough",
                    "subscript",
                    "superscript",
                    "|",
                    "paragraphFormat",
                    "align",
                    "formatOL",
                    "formatUL",
                    "outdent",
                    "indent",
                    "quote",
                    "|",
                    "insertLink",
                    "embedly",
                    "|",
                    "emoticons",
                    "insertHR",
                    "clearFormatting",
                    "|",
                    "spellChecker",
                    "|",
                    "undo",
                    "redo",
                    "html",
                  ],
                  colorsBackground: [
                    "#15E67F",
                    "#E3DE8C",
                    "#D8A076",
                    "#D83762",
                    "#76B6D8",
                    "REMOVE",
                    "#1C7A90",
                    "#249CB8",
                    "#4ABED9",
                    "#FBD75B",
                    "#FBE571",
                    "#FFFFFF",
                  ],
                }}
                model={this.state[this.state.modelAtivo]}
                onModelChange={(text) =>
                  this.setState({ [this.state.modelAtivo]: text })
                }
              />
              <Dialog open={this.state.dialogEmail}>
                <DialogTitle>Confirme o e-mail antes de enviar</DialogTitle>

                <DialogContent>
                  <Paper style={{ padding: "15px" }}>
                    <h2>Assunto: {this.props.titulo}</h2>
                    <FroalaEditorView model={this.state.model} />
                  </Paper>
                </DialogContent>

                <DialogActions>
                  <ButtonMUI
                    color="primary"
                    variant="contained"
                    disabled={this.state.loading}
                    endIcon={<Icon>send</Icon>}
                    onClick={() => this.handleSendEmail()}
                  >
                    Enviar
                  </ButtonMUI>

                  {this.state.loading && <CircularProgress />}

                  <ButtonMUI
                    disabled={this.state.loading}
                    onClick={() => {
                      this.setState({ dialogEmail: false });
                    }}
                  >
                    Cancelar
                  </ButtonMUI>
                </DialogActions>
              </Dialog>

              <Dialog open={this.state.warningDialog.open}>
                <DialogContent>
                  <DialogContentText>
                    {this.state.warningDialog.context}
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <ButtonMUI
                    onClick={() =>
                      this.setState({ warningDialog: { open: false } })
                    }
                  >
                    Fechar
                  </ButtonMUI>
                </DialogActions>
              </Dialog>

              <div style={{ marginTop: ".5rem" }}>
                <Button
                  color="green"
                  text={`${
                    this.state.modelAtivo === "modelPadrao"
                      ? "Salvar padrão"
                      : "Salvar laudo"
                  }`}
                  onClick={() => {
                    this.salvar();
                  }}
                />
                <Button
                  text="Cancelar"
                  color="secondary"
                  onClick={() => {
                    if (this.state.modelAtivo === "modelPadrao") {
                      this.setState({
                        modelAtivo: "model",
                        modelPadrao: this.state.modelPadraoTemp,
                      });
                    } else {
                      this.setState({
                        modalLaudo: false,
                        modelAtivo: "model",
                        model: this.state.modelTemp,
                      });
                    }
                  }}
                  right
                />
              </div>
            </div>
          </Dialog>
        </Dialog>
        <Dialog
          open={this.state.dialogMilimeter}
          onClose={() =>
            this.setState({ ...this.state, dialogMilimeter: false })
          }
        >
          <DialogTitle>Ajuste de escala</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Indique um valor em milímetros de uma distância conhecida na
              imagem.
            </DialogContentText>
            <TextField
              fullWidth
              label="Valor em milímetros"
              value={this.state.milimeter}
              onChange={(e) =>
                this.setState({ ...this.state, milimeter: e.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <ButtonMUI onClick={() => this.handleSubmitMillimeter()}>
              Salvar
            </ButtonMUI>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogPontoA}
          onClose={() =>
            this.state.setState({ ...this.state, dialogPontoA: false })
          }
        >
          <DialogTitle>Posição A</DialogTitle>
          <DialogContent>Indique o ponto A na imagem</DialogContent>
          <DialogActions>
            <ButtonMUI onClick={() => this.handleSubmitPontoA()}>
              Indicar
            </ButtonMUI>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogPontoB}
          onClose={() =>
            this.state.setState({ ...this.state, dialogPontoB: false })
          }
        >
          <DialogTitle>Posição B</DialogTitle>
          <DialogContent>Indique o ponto B na imagem</DialogContent>
          <DialogActions>
            <ButtonMUI onClick={() => this.handleSubmitPontoB()}>
              Indicar
            </ButtonMUI>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect(null, { showMessageSystem, sendEmail })(MyComponent);
