import React, { useState, useEffect, memo } from "react";
import MUIDataTable from "mui-datatables";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Add, Email } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect } from "react-redux";
import {
  abrirModalConfirmacao,
  esconderModalConfirmacao,
} from "../../actions/notificacao/NotificacaoAction";
import { ThemeProvider } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Tabela = memo((props) => {
  const {
    titulo,
    corTitulo,
    colunas,
    objetos,
    inserir,
    funcoes,
    selecionarLinhas,
    otherOptions,
    theme,
  } = props;

  const [rowsDeleted, setRowsDeleted] = useState("");
  const [listaObjetos, setObjetos] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setObjetos(objetos);
  }, [objetos]);

  colunas.map((coluna) => {
    if (coluna.name === "funcoes") {
      coluna.options = { 
        ...coluna.options, 
        viewColumns: false,
        customBodyRender: (value) => {
          return <div style={{ textAlign: 'right' }}>{value}</div>;
        }
      };
    }
  });
  
  function onRowsDeleted() {
    var deletar = [];

    rowsDeleted.data.map((d) => {
      var objeto = listaObjetos.filter((_, index) => {
        return index === d.dataIndex;
      });

      deletar.push(objeto);
    });

    funcoes.deletar(deletar);
  }

  const options = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    responsive: "stacked",
    elevation: 1,
    rowsPerPage: 10,
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado!",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página",
        displayRows: "de",
      },
      toolbar: {
        search: "Busca",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Visualizar Colunas",
        filterTable: "Filtrar Tabela",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Mostrar Colunas",
        titleAria: "Mostrar/Esconder Colunas da Tabela",
      },
      selectedRows: {
        text: "linha(s) selecionada(s)",
        delete: "Deletar",
        deleteAria: "Deletar Linhas Seleciondas",
      },
    },
    onRowsDelete: (rows) => {
      if (funcoes !== undefined) {
        setOpen(true);
        setRowsDeleted(rows);

        return false;
      }
    },
    customToolbar: () => {
      return !inserir ? (
        ""
      ) : (
        <Tooltip title={"Inserir"}>
          <IconButton onClick={() => props.history.push(inserir.link)}>
            <Add />
          </IconButton>
        </Tooltip>
      );
    },
    customToolbarSelect: (selectedRows) => {
      const dataRows = selectedRows.data.map(
        (row) => listaObjetos[row.dataIndex]
      );
      return (
        funcoes?.handleSelectedRows && (
          <Tooltip title={"Email"}>
            <IconButton
              onClick={() => {
                funcoes.handleSelectedRows(dataRows);
              }}
            >
              <Email />
            </IconButton>
          </Tooltip>
        )
      );
    },
    ...otherOptions,
  };

  // if (
  //   (listaObjetos.length === 0 && objetos.length > 0) ||
  //   objetos.length !== listaObjetos.length
  // ) {
  //   setObjetos(objetos);
  // }

  return (
    <div>
      <ThemeProvider theme={theme ?? {}}>
        <MUIDataTable
          title={titulo}
          columns={colunas}
          data={listaObjetos}
          options={options}
          theme={theme}
        />
      </ThemeProvider>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Está prestes a realizar uma ação importante"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Você tem certeza de que deseja prosseguir?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Não
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              onRowsDeleted();
            }}
            color="primary"
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

function mapStateToProps(state) {
  return {
    modalConfirmacao: state.modalConfirmacao,
  };
}

export default connect(mapStateToProps, {
  abrirModalConfirmacao,
  esconderModalConfirmacao,
})(Tabela);
