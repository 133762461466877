import React, { useEffect, useState } from "react";
import {
  addEndodonticConfigOption,
  listEndodonticConfig,
} from "../../../api/endodonticConfig";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Add } from "@material-ui/icons";
import { toast } from "react-toastify";

export default function EndodonticConfigForm() {
  const [endodontics, setEndodontics] = useState([]);
  const [modal, setModal] = useState({
    open: false,
    id: "",
    value: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await listEndodonticConfig();
        const newList = data.map((item) => {
          item.value = "";
          return item;
        });
        setEndodontics(newList);
      } catch (e) {
        toast.error(e.message);
      }
    };
    fetchData();
  }, []);

  const handleChangeValue = (index, value) => {
    const newEndodontics = [...endodontics];
    newEndodontics[index].value = value;
    setEndodontics(newEndodontics);
  };

  const handleCloseModal = () => {
    setModal({ open: false, value: "", id: "" });
  };

  const handleAddModal = (id) => {
    setModal({ open: true, value: "", id });
  };

  const handleChangeValueModal = (e) => {
    setModal({ ...modal, value: e.target.value });
  };

  const handleSave = async () => {
    try {
      await addEndodonticConfigOption(modal.id, modal.value);
      toast.success("Inseriddo com sucesso!");
      handleCloseModal();
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      <Container component={Paper} style={{ padding: "16px" }}>
        <Grid container spacing={3}>
          {endodontics.map((endodontic, index) => (
            <Grid item key={endodontic.name} xs={12}>
              <Autocomplete
                options={endodontic.options}
                value={endodontic.value}
                getOptionLabel={(option) => option.name ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={endodontic.label}
                    variant="outlined"
                  />
                )}
                onChange={(_, newValue) => handleChangeValue(index, newValue)}
              />
              <IconButton onClick={() => handleAddModal(endodontic._id)}>
                <Add />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Dialog open={modal.open} maxWidth="sm">
        <DialogTitle>Novo</DialogTitle>
        <DialogContent>
          <TextField
            label="Nome do procedimento"
            value={modal.value}
            onChange={handleChangeValueModal}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Fechar</Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disableElevation
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
