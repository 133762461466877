import React, { Component } from "react";
import { connect } from "react-redux";
import { deletarStatus, listarStatus } from "../../../../api/pacientes";
import { editPatient } from "../../../../actions/patientsSearch";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import TableComponent from "../../../table/TableComponent";

//Icone de arquivar
import Edit from "@material-ui/icons/Edit";
import Pageview from "@material-ui/icons/Pageview";

class PacientesConfigStatus extends Component {
  state = {
    status: [],
  };

  deletaStatus(objeto) {
    const { clinica } = this.props;
    deletarStatus(objeto, clinica._id);
  }

  componentWillMount() {
    listarStatus(this.props.clinica._id)
      .then((arrStatus) => {
        var listaStatus = [];

        arrStatus.length > 0 &&
          arrStatus.map((status, index) => {
            var novoStatus = {};

            novoStatus.status = status.status;
            novoStatus.statusId = status._id;
            novoStatus.funcoes = [
              <Tooltip title={"Editar Status"} key={index}>
                <IconButton
                  onClick={() =>
                    this.props.history.push(
                      `/pacientes/config/cadastrarStatus/${status._id}`
                    )
                  }
                >
                  <Edit />
                </IconButton>
              </Tooltip>,
              <Tooltip key={status._id} title={"Listar Itens"}>
                <IconButton
                  onClick={() =>
                    this.props.history.push(
                      `${this.props.match.url}/${status._id}`
                    )
                  }
                >
                  <Pageview />
                </IconButton>
              </Tooltip>,
            ];

            listaStatus.push(novoStatus);
          });

        this.setState({ status: listaStatus });
      })
      .catch((erro) => console.log(erro));
  }

  render() {
    const titulo = "Status",
      colunas = [
         { label: "Tipo de Status", name: "status" },

        { label: " ", name: "funcoes", options: {
          setCellProps: () => ({
            style: {
              width: '150px' //Define a largura da coluna
            },
          }),
          filter: false, sort: false } }, 
      ],
      objetos = this.state.status,
      inserir = {
        nome: "Cadastrar Status",
        link: "cadastrarStatus",
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.deletaStatus.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps, { editPatient })(PacientesConfigStatus);
