import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { fetchInstallments } from "../../../api/financeiro";
import {
  Chip,
  Grid,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Container,
  Paper,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import TableComponent from "../../table/TableComponent";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Check } from "@material-ui/icons";
import {
  confirmPayment,
  getBudget,
  listParcelsByBudget,
  getBudgetResume,
  parcelPay,
} from "../../../api/budget";
import ParcelReceiptFormDialog from "./orcamento/ParcelReceiptFormDialog";
import { toast } from "react-toastify";
import SimpleDialog from "../../dialog/SimpleDialog";

const currentDate = new Date();

const FinancialForm = ({ selectedPatient }) => {
  const [objetos, setObjetos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("all");
  const [startDate, setStartDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );

  const [parcelReceiptFormDialog, setParcelReceiptFormDialog] = useState({
    open: false,
    parcelId: "",
    budgetId: "",
  });
  const [confirmPaymentDialog, setConfirmPaymentDialog] = useState({
    open: false,
    parcelId: "",
  });

  const handleConfirmPayment = (parcelId, budgetId) => {
    setConfirmPaymentDialog({ open: true, parcelId, budgetId });
  };

  const submitConfirmPayment = async (parcelId, budgetId) => {
    try {
      if (!budgetId) {
        toast.error("Orçamento não encontrado. Verifique e tente novamente.");
        return;
      }
      const type = parcelId ? "parcel" : "entrance";
      await confirmPayment({ budgetId, parcelId, type });

      toast.success("Pagamento confirmado com sucesso.");
      setConfirmPaymentDialog({ open: false, parcelId: "", budgetId: "" });

      await refreshData();
    } catch (error) {
      toast.error("Erro ao confirmar pagamento.");
    }
  };

  const closeConfirmPaymentDialog = () => {
    setConfirmPaymentDialog({ open: false, parcelId: "", budgetId: "" });
  };

  const handlePayParcel = async (parcelId, payment) => {
    try {
      const nextPayment = {
        payment,
        budgetId: parcelReceiptFormDialog.budgetId,
        parcelId,
      };
      await parcelPay(nextPayment);
      toast.success("Pagamento realizado com sucesso.");
      closeParcelReceiptDialog();
      await refreshData();
    } catch (error) {
      toast.error("Erro ao completar pagamento");
    }
  };

  const refreshData = async () => {
    setIsLoading(true);
    const result = await fetchInstallments({
      startDate,
      endDate,
      search,
      patientId: selectedPatient._id,
    });

    const modifiedData = result.data.map((parcel) => ({
      ...parcel,
      functions: {
        parcelId: parcel._id,
        budgetId: parcel.budgetId,
        paid: parcel.paid,
        confirmPayment: parcel.confirm ?? false,
      },
    }));

    setObjetos(modifiedData);
    setIsLoading(false);
  };

  const receiptParcel = useCallback((parcelId, budgetId) => {
    setParcelReceiptFormDialog({ open: true, parcelId, budgetId });
  }, []);

  const closeParcelReceiptDialog = () => {
    setParcelReceiptFormDialog({ open: false, parcelId: "", budgetId: "" });
  };

  useEffect(() => {
    if (selectedPatient) {
      const fetchData = async () => {
        setIsLoading(true);
        const result = await fetchInstallments({
          startDate,
          endDate,
          search: "all",
          patientId: selectedPatient._id,
        });
        const modifiedData = result.data.map((parcel) => ({
          ...parcel,
          functions: {
            parcelId: parcel.parcelId,
            budgetId: parcel._id,
            paid: parcel.paid,
            confirmPayment: parcel.confirm ?? false,
          },
        }));
        setObjetos(modifiedData);
        setIsLoading(false);
      };
      fetchData();
    }
  }, [selectedPatient, startDate, endDate]);
  const searchPatient = async () => {
    await refreshData();
  };

  const colunas = [
    {
      label: "Vencimento",
      name: "expiration",
      options: {
        customBodyRender: (value) => {
          return value ? moment(value).format("DD/MM/YYYY") : null;
        },
      },
    },
    { label: "Fatura", name: "number" },
    { label: "Orçamento", name: "budgetName" },
    {
      label: "Data Recebimento",
      name: "datePayment",
      options: {
        customBodyRender: (value) => {
          return value && moment(value).format("DD/MM/YYYY");
        },
      },
    },
    {
      label: "Valor Recebimento",
      name: "valuePayment",
      options: {
        customBodyRender: (value) => {
          return (
            value &&
            value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
      },
    },
    {
      label: "Valor",
      name: "value",
      options: {
        customBodyRender: (value) => {
          return (
            value &&
            value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value) => {
          let color = "";
          let label = "";
          switch (value) {
            case "paid":
              color = "#198754";
              label = "Pago";
              break;
            case "overdue":
              color = "#dc3545";
              label = "Vencido";
              break;
            default:
              color = "#0d6efd";
              label = "Aberto";
              break;
          }
          return (
            <Chip
              size="small"
              label={label}
              style={{ background: `${color}`, color: "white" }}
            />
          );
        },
      },
    },
    {
      label: " ",
      name: "functions",
      options: {
        customBodyRender: (value) => {
          if (!value) return null;
          const { paid, parcelId, budgetId, confirmPayment } = value;

          if (!paid) {
            console.log("jean");
            return (
              <Button onClick={() => receiptParcel(parcelId, budgetId)}>
                Pagar
              </Button>
            );
          }
          if (paid && !confirmPayment) {
            return (
              <IconButton
                title="Confirmar pagamento"
                onClick={() => handleConfirmPayment(parcelId, budgetId)}
              >
                <Check />
              </IconButton>
            );
          }
        },
      },
    },
  ];

  return (
    <Container component={Paper} style={{ padding: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Data Inicial"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              value={startDate}
              onChange={(e) => setStartDate(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={3}>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              label="Data Final"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              value={endDate}
              onChange={(e) => setEndDate(e)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={3}>
          <TextField
            fullWidth
            label="Filtrar pacientes"
            variant="outlined"
            select
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          >
            <MenuItem value="all">Todas</MenuItem>
            <MenuItem value="paid">Pagas</MenuItem>
            <MenuItem value="open">Abertas</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={2}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={searchPatient}
          >
            Buscar
          </Button>
        </Grid>

        <Grid item xs={12}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <TableComponent colunas={colunas} objetos={objetos} />
          )}
        </Grid>
      </Grid>

      {parcelReceiptFormDialog.open && (
        <ParcelReceiptFormDialog
          open={parcelReceiptFormDialog.open}
          budgetId={parcelReceiptFormDialog.budgetId}
          parcelId={parcelReceiptFormDialog.parcelId}
          onClose={closeParcelReceiptDialog}
          onSubmit={handlePayParcel}
        />
      )}

      {confirmPaymentDialog.open && (
        <SimpleDialog
          open={confirmPaymentDialog.open}
          title="Confirmar pagamento?"
          content="Após confirmado, a operação não poderá ser desfeita."
          handleClose={closeConfirmPaymentDialog}
          handleSubmit={() =>
            submitConfirmPayment(
              confirmPaymentDialog.parcelId,
              confirmPaymentDialog.budgetId
            )
          }
          submitButtonTitle="Confirmar"
          closeButtonTitle="Cancelar"
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedPatient: state.patientsCreation.selectedPatient,
  };
};

export default connect(mapStateToProps)(FinancialForm);
