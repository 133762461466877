import {
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { filterEndodontic } from "../../../api/pacientes";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import EndodonticReportPDF from "./endodontic_report_pdf";
import { teeth } from "../../../utils/teeth";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import { endodonticChannels } from "../../../utils/endodontic";
import { listEndodonticConfig } from "../../../api/endodonticConfig";

const listTooth = teeth.map((tooth) => tooth.toString());

const defaultProcedureFilters = {
  xray: "",
  diagnostic: "",
  dentalTrauma: "",
  procedure: "",
  curative: "",
  irrigationSolutions: "",
  preparionTechniques: "",
  dentalCement: "",
  typeFilling: "",
};

const defaultChannelFilters = {
  channel: "",
  odontometric: "",
  realLength: "",
  workLength: "",
  lime: "",
  drill: "",
  core: "",
  reference: "",
};

const inputsTreatmentDate = [
  { name: "treatmentInitialDate", label: "Data do tratamento" },
  { name: "treatmentFinalDate", label: "Até a data" },
];

const inputsProcedures = [
  { name: "xray", label: "Raio-X" },
  { name: "diagnostic", label: "Diagnóstico" },
  { name: "dentalTrauma", label: "Trauma" },
  { name: "procedure", label: "Procedimento" },
  { name: "curative", label: "Curativos" },
  { name: "irrigationSolutions", label: "Soluções irrigadoras" },
  { name: "preparionTechniques", label: "Técnica de Preparo" },
  { name: "dentalCement", label: "Cimento obturador" },
  { name: "typeFilling", label: "Tipo de obturação" },
];

const inputsChannels = endodonticChannels.filter(
  (channel) => channel.name !== "reference"
);

const defaultHeader = [
  { name: "patient", label: "Paciente", checked: true },
  { name: "birthday", label: "Data Nasc.", checked: true },
  { name: "tooth", label: "Dente", checked: true },
  { name: "channel", label: "Ca.", checked: true },
  { name: "odontometric", label: "Odtm.", checked: true },
  { name: "realLength", label: "C. Re.", checked: true },
  { name: "workLength", label: "C. Tr.", checked: true },
  { name: "lime", label: "Limas", checked: true },
  { name: "drill", label: "Broca", checked: true },
  { name: "core", label: "Núcl.", checked: true },
  { name: "reference", label: "Refer.", checked: true },
  { name: "xray", label: "Raio-X", checked: true },
  { name: "diagnostic", label: "Diagn.", checked: true },
  { name: "dentalTrauma", label: "Trauma", checked: true },
  { name: "procedure", label: "Proc.", checked: true },
  { name: "curative", label: "Curat.", checked: true },
  { name: "irrigationSolutions", label: "S. Irr.", checked: true },
  { name: "preparionTechniques", label: "T. Prep.", checked: true },
  { name: "dentalCement", label: "C. Obt.", checked: true },
  { name: "typeFilling", label: "Obtur.", checked: true },
];

export default function EndodonticReport() {
  const [mainFilters, setMainFilters] = useState({
    tooth: null,
    treatmentInitialDate: null,
    treatmentFinalDate: null,
    gender: null,
  });
  const [listProcedures, setListProcedures] = useState([]);
  const [procedureFilters, setProcedureFilters] = useState([]);
  const [channelFilters, setChannelFilters] = useState(defaultChannelFilters);
  const [pdfHeader, setPdfHeader] = useState(defaultHeader);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await listEndodonticConfig();
      setListProcedures(data);
      setProcedureFilters(
        data.map((procedure) => {
          return {
            ...procedure,
            options: [],
          };
        })
      );
    };

    fetchData();
  }, []);

  const onSubmit = async () => {
    try {
      const params = {
        ...mainFilters,
        channels: channelFilters,
        procedures: procedureFilters,
      };

      const { data } = await filterEndodontic(params);

      if (!data || !data.data.length) {
        return toast.error("Nenhum resultado encontrado!");
      }

      const pdfData = data.data.map((elem) => {
        const nextProcedures = {};
        elem.procedures.forEach((procedure) => {
          nextProcedures[procedure.name] = procedure.value?.name;
        });

        return {
          patient: `${elem.name} ${elem.lastName}`,
          birthday: moment(elem.birthday).format("DD/MM/YYYY"),
          tooth: elem.tooth,
          channel: elem.channel.channel,
          odontometric: elem.channel.odontometric,
          realLength: elem.channel.realLength,
          workLength: elem.channel.workLength,
          lime: elem.channel.lime,
          drill: elem.channel.drill,
          core: elem.channel.core,
          reference: elem.channel.reference,
          ...nextProcedures,
        };
      });

      const header = pdfHeader.filter((elem) => elem.checked);

      const blob = await pdf(
        <EndodonticReportPDF
          data={{
            pdfData,
            totalPatients: data.totalPatients,
            totalRecords: data.totalRecords,
          }}
          header={header}
        />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.log(error);
      toast.error("Erro ao gerar relatório");
    }
  };

  const handleChangePdfHeader = (name, value) => {
    const index = pdfHeader.findIndex((header) => header.name === name);
    const nextPdfHeader = [...pdfHeader];
    nextPdfHeader[index].checked = value;
    setPdfHeader(nextPdfHeader);
  };

  const handleChangeProcedureOptions = (index, newOptions) => {
    const newProcedureFilters = JSON.parse(JSON.stringify(procedureFilters));
    newProcedureFilters[index].options = newOptions;
    setProcedureFilters(newProcedureFilters);
  };

  const renderTreatmentDate = () => {
    return inputsTreatmentDate.map((treatmentDate) => (
      <Grid item xs={6} key={treatmentDate.name}>
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            label={treatmentDate.label}
            format="dd/MM/yyyy"
            value={mainFilters[treatmentDate.name]}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            onChange={(date) => {
              setMainFilters({ ...mainFilters, [treatmentDate.name]: date });
            }}
            inputVariant="outlined"
            // error={errors.concludedDate}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    ));
  };

  const renderTooth = () => {
    return (
      <Grid item xs={2}>
        <Autocomplete
          value={mainFilters.tooth}
          onChange={(_, newValue) => {
            setMainFilters({
              ...mainFilters,
              tooth: newValue,
            });
          }}
          options={listTooth}
          renderInput={(params) => (
            <TextField {...params} label={"Dente"} variant="outlined" />
          )}
        />
      </Grid>
    );
  };

  const renderChannels = () => {
    const numberChannels = inputsChannels.map((channel) => (
      <Grid item xs={2} key={channel.name}>
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          label={channel.label}
          value={channelFilters[channel.name]}
          onChange={(e) => {
            setChannelFilters({
              ...channelFilters,
              [channel.name]: e.target.valueAsNumber || "",
            });
          }}
        />
      </Grid>
    ));

    const referenceChannel = (
      <Grid item xs={4}>
        <TextField
          fullWidth
          select
          label="Reference"
          variant="outlined"
          value={channelFilters.reference}
          onChange={(e) =>
            setChannelFilters({ ...channelFilters, reference: e.target.value })
          }
        >
          <MenuItem value="">
            <i>Nenhum</i>
          </MenuItem>
          <MenuItem value="teste">Teste</MenuItem>
        </TextField>
      </Grid>
    );

    return (
      <>
        {numberChannels}
        {referenceChannel}
      </>
    );
  };

  const renderProcedures = () => {
    return (
      procedureFilters.length &&
      listProcedures.length &&
      listProcedures.map((procedure, index) => (
        <Grid item xs={6} key={procedure.name}>
          <Autocomplete
            multiple
            value={procedureFilters[index].options}
            onChange={(_, newValue) => {
              handleChangeProcedureOptions(index, newValue);
            }}
            options={procedure.options}
            getOptionLabel={(option) => option.name || ""}
            renderOption={(option) => <span>{option.name}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                label={procedure.label}
                variant="outlined"
              />
            )}
          />
        </Grid>
      ))
    );
  };

  const renderPdfHeader = () => {
    return (
      <Grid item xs={12}>
        {pdfHeader.map((head) => (
          <FormControlLabel
            key={head.name}
            label={head.label}
            control={
              <Checkbox
                checked={head.checked}
                color="primary"
                onChange={(e) =>
                  handleChangePdfHeader(head.name, e.target.checked)
                }
              />
            }
          />
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Typography variant="h5" fontWeight="bold">
        Estatística da Endodontia
      </Typography>
      <Container
        component={Paper}
        style={{ padding: "16px", marginTop: "20px" }}
      >
        <Grid container spacing={2}>
          {renderTreatmentDate()}
          {renderTooth()}
          {renderChannels()}
          {renderProcedures()}
          {renderPdfHeader()}
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={onSubmit}
            >
              Gerar relatório
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
