import React, { Component } from "react";
import { connect } from "react-redux";
import TableComponent from "../../../table/TableComponent";
import Edit from "@material-ui/icons/Edit";
import { listarRecebimentos } from "../../../../api/clientes";
var moment = require("moment");

class RecebimentosPacienteListagem extends Component {
  state = {
    recebimentos: [],
  };

  componentWillMount() {
    listarRecebimentos(this.props.clinica._id).then((recebimentos) => {
      var listaRecebimentos = [];

      recebimentos.length > 0 &&
        recebimentos.map((recebimento, index) => {
          var novo = {};

          novo.patient_id = recebimento.patient_id;
          novo.valorTotal = parseFloat(recebimento.valorTotal).toFixed(2);
          novo.dtVencimento = moment(recebimento.dtVencimento).format(
            "DD/MM/YYYY",
          );
          novo.qtdParcelas = recebimento.qtdParcelas;
          novo.nrParcela = recebimento.nrParcela;
          novo.dtCompensacao = moment(recebimento.dtCompensacao).format(
            "DD/MM/YYYY",
          );
          novo.dentist_id = recebimento.dentist_id;
          novo.convenio = recebimento.convenio || "";
          novo.funcoes = [
            <Edit
              key={index}
              onClick={() =>
                this.props.history.push(
                  `/recebimentos/cadastrarRecebimentoPaciente/${recebimento._id}`,
                )
              }
            />,
          ];
          listaRecebimentos.push(novo);
        });
      this.setState({ recebimentos: listaRecebimentos });
    });
  }

  render() {
    const titulo = "Recebimentos",
      colunas = [
        { label: "Paciente", name: "patient_id" },
        { label: "Valor Devido", name: "valorTotal" },
        { label: "Data Vencimento", name: "dtVencimento" },
        { label: "Quantidade de Parcelas", name: "qtdParcelas" },
        { label: "Nº Prestação", name: "nrParcela" },
        { label: "Convênio", name: "convenio" },
        {
          label: " ",
          name: "funcoes",
          options: { filter: false, sort: false },
        },
      ],
      objetos = this.state.recebimentos,
      inserir = {
        nome: "Cadastrar Recebimento",
        link: "cadastrarRecebimentoPaciente",
      };
    return (
      <TableComponent
        titulo={titulo}
        selecionarLinhas={false}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(RecebimentosPacienteListagem);
