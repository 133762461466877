export const endodonticChannels = [
  {
    label: "Canal",
    name: "channel",
  },
  {
    label: "Odontometria",
    name: "odontometric",
  },
  {
    label: "Comp. Real",
    name: "realLength",
  },
  {
    label: "Comp. Trabalho",
    name: "workLength",
  },
  {
    label: "Limas",
    name: "lime",
  },
  {
    label: "Broca",
    name: "drill",
  },
  {
    label: "Núcleo",
    name: "core",
  },
  {
    label: "Referência",
    name: "reference",
  },
];

export const endodonticProceduresObjectFormat = {
  xray: {
    label: "Raio-X",
  },
  diagnostic: {
    label: "Diagnóstico",
  },
  dentalTrauma: {
    label: "Trauma Dental",
  },
  procedure: {
    label: "Procedimento",
  },
  curative: {
    label: "Curativos",
  },
  irrigationSolutions: {
    label: "Soluções Irrigadoras",
  },
  preparionTechniques: {
    label: "Técnica de Preparo",
  },
  dentalCement: {
    label: "Cimento Obturador",
  },
  typeFilling: {
    label: "Tipo de Obturação",
  },
};

export const endodonticProcedures = [
  {
    label: "Raio-X",
    name: "xray",
  },
  {
    label: "Diagnóstico",
    name: "diagnostic",
  },
  {
    label: "Trauma Dental",
    name: "dentalTrauma",
  },
  {
    label: "Procedimento",
    name: "procedure",
  },
  {
    label: "Curativos",
    name: "curative",
  },
  {
    label: "Soluções Irrigadoras",
    name: "irrigationSolutions",
  },
  {
    label: "Técnica de Preparo",
    name: "preparionTechniques",
  },
  {
    label: "Cimento Obturador",
    name: "dentalCement",
  },
  {
    label: "Tipo de Obturação",
    name: "typeFilling",
  },
];

export const endodonticSessions = [
  { label: "Data", name: "date" },
  { label: "Abertura", name: "opening" },
  { label: "Preparação", name: "preparation" },
  { label: "Medição", name: "measurement" },
  { label: "Selamento", name: "sealing" },
  { label: "Obturação", name: "obturation" },
  { label: "Drenagem", name: "dressing" },
];

export const endodonticOdontometric = [
  { name: "digitalDenistry", label: "Odontometria Eletrônica" },
  { name: "ultrasonicInstrumentation", label: "Instrumentação Ultrasônica" },
  { name: "digitalXray", label: "Raio X Digital" },
];
