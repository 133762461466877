import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TableComponent from "../../../table/TableComponent";
import { Add, Edit } from "@material-ui/icons";
import moment from "moment";
import { getListEndodontic } from "../../../../api/pacientes";
import { endodonticProcedures } from "../../../../utils/endodontic";
import { filter } from "lodash";

export default function EndodonticList({ history, match, patientId }) {
  const [listEndodontic, setListEndodontic] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getEndodontics = async () => {
      try {
        const { data } = await getListEndodontic(patientId);
        setListEndodontic(data);
        setIsLoading(false);
      } catch (error) {}
    };
    getEndodontics();
  }, []);

  const columns = [
    { label: "Dente", name: "tooth" },
    {
      label: "1 Sessão",
      name: "firstSessionDate",
      options: {
        customBodyRender: (value) =>
          value && moment(value).format("DD/MM/YYYY"),
      },
    },
    ...endodonticProcedures,
    {
      label: " ",
      name: "_id",
      options: {
        display: "excluded",
        filter: false,
        customBodyRender: (value) => (
          <IconButton
            onClick={() => history.push(`${match.url}/${value}/edit`)}
          >
            <Edit />
          </IconButton>
        ),
      },
    },
  ];
  return (
    <Container maxWidth="lg" component={Paper} style={{ padding: "16px" }}>
      <Box display="flex" style={{ marginBottom: "16px" }}>
        <Box flexGrow={1}>
          <Typography variant="h5" gutterBottom>
            Ficha endodôntica
          </Typography>
        </Box>
        <Box>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            startIcon={<Add />}
            onClick={() => history.push(`${match.url}/new`)}
          >
            Novo
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableComponent colunas={columns} objetos={listEndodontic} />
      )}
    </Container>
  );
}
