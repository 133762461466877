import React, { useState, useEffect } from "react";
import {
  formataMoedaReal,
} from "../../../helpers/Helpers";
import moment from "moment";
import { fetchPendingBudgets } from "../../../api/financeiro";
import MUIDataTable from "mui-datatables";
import TableComponent from "../../table/TableComponent";


const OrcamentosPendentes = () => {
  const [pendingBudgets, setPendingBudgets] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchPendingBudgets();
      setPendingBudgets(result);
    };
    fetchData();
  }, []);

  const colunas = [
    { label: "Paciente", name: "patient.name" },
    { label: "Orçamento", name: "name" },
    {
      label: "Validade",
      name: "budget_expiration",
      options: {
        customBodyRender: (value) => {
          return value && moment(value).format("DD/MM/YYYY");
        },
      },
    },
    {
      label: "Valor",
      name: "valorTotalProcedimentos",
      options: {
        customBodyRender: (value) => formataMoedaReal(value),
      },
    },
  ];

  const options = {
    elevation: 1,
    selectableRows: "none",
  };

  return (
    <TableComponent
      titulo="Orçamentos pendentes"
      colunas={colunas}
      objetos={pendingBudgets}
      options={options}  
    />
  );
};

export default OrcamentosPendentes;
