import { variables } from "./variables";

const replacePatientVariables = (message, patient, dentistData) => {
  if (patient) {
    message = message.replace(
      /{{Paciente_numero_cadastro}}/g,
      patient.registration_number || ""
    );
    message = message.replace(/{{Paciente_cadastro}}/g, patient.register || "");
    message = message.replace(/{{Paciente_nome}}/g, patient.name || "");
    message = message.replace(
      /{{Paciente_sobrenome}}/g,
      patient.lastName || ""
    );
    message = message.replace(
      /{{Paciente_telefone}}/g,
      patient.telephone || ""
    );
    message = message.replace(
      /{{Paciente_situacoes}}/g,
      patient.situations || ""
    );
    message = message.replace(/{{Paciente_email}}/g, patient.email || "");
    message = message.replace(/{{Paciente_endereco}}/g, patient.address || "");
    message = message.replace(/{{Paciente_estado}}/g, patient.state || "");
    message = message.replace(/{{Paciente_cidade}}/g, patient.city || "");
    message = message.replace(/{{Paciente_cep}}/g, patient.zip_code || "");
    message = message.replace(
      /{{Paciente_registro}}/g,
      patient.registration || ""
    );
    message = message.replace(
      /{{Paciente_data_do_primeiro_atendimento}}/g,
      patient.date_of_first_service || ""
    );
    message = message.replace(
      /{{Paciente_data_de_retorno}}/g,
      patient.return_date || ""
    );
    message = message.replace(
      /{{Paciente_data_de_nascimento}}/g,
      patient.date_of_birth || ""
    );
    message = message.replace(/{{Paciente_idade}}/g, patient.age || "");
    message = message.replace(/{{Paciente_genero}}/g, patient.gender || "");
    message = message.replace(
      /{{Paciente_estado_civil}}/g,
      patient.civil_status || ""
    );
    message = message.replace(
      /{{Paciente_profissao}}/g,
      patient.profession || ""
    );
    message = message.replace(/{{Paciente_rg}}/g, patient.rg || "");
    message = message.replace(/{{Paciente_cpf}}/g, patient.cpf || "");
    message = message.replace(
      /{{Paciente_empresa}}/g,
      patient.enterprise || ""
    );
    message = message.replace(/{{Paciente_pai}}/g, patient.father || "");
    message = message.replace(/{{Paciente_mae}}/g, patient.mother || "");
    message = message.replace(
      /{{Paciente_pai_profissao}}/g,
      patient.father_profession || ""
    );
    message = message.replace(
      /{{Paciente_profissao_da_mae}}/g,
      patient.mother_profession || ""
    );
    message = message.replace(
      /{{Paciente_convenio}}/g,
      patient.agreement || ""
    );
    message = message.replace(
      /{{Paciente_numero_de_convenio}}/g,
      patient.number_agreement || ""
    );
    message = message.replace(
      /{{Paciente_patrocinador}}/g,
      patient.sponsor || ""
    );
    message = message.replace(
      /{{Paciente_numero_do_patrocinador}}/g,
      patient.number_sponsor || ""
    );
    message = message.replace(
      /{{Paciente_cpf_do_patrocinador}}/g,
      patient.cpf_sponsor || ""
    );
    message = message.replace(
      /{{Paciente_indicacao}}/g,
      patient.indication || ""
    );
    message = message.replace(
      /{{Paciente_primeira_consulta}}/g,
      patient.first_consultation || ""
    );
    message = message.replace(
      /{{Paciente_tempo_de_tratamento}}/g,
      patient.treatment_time || ""
    );
    message = message.replace(/{{Paciente_foto}}/g, patient.photo || "");
    message = message.replace(/{{Paciente_status}}/g, patient.status || "");
    message = message.replace(/{{Paciente_ativo}}/g, patient.active || "");
    message = message.replace(/{{Paciente_extra}}/g, patient.extra || "");
    message = message.replace(
      /{{Paciente_prescricao}}/g,
      patient.prescription || ""
    );
    message = message.replace(/{{Paciente_pasta}}/g, patient.folder || "");
    message = message.replace(
      /{{Paciente_nota_clinica}}/g,
      patient.clinical_note || ""
    );
    message = message.replace(
      /{{Paciente_endodontia}}/g,
      patient.endodontics || ""
    );
    message = message.replace(
      /{{Paciente_odontograma}}/g,
      patient.odontogram || ""
    );
    message = message.replace(/{{Paciente_galeria}}/g, patient.gallery || "");
    message = message.replace(/{{Paciente_arquivos}}/g, patient.files || "");
    message = message.replace(/{{Paciente_anamnese}}/g, patient.anamnese || "");
    message = message.replace(
      /{{Paciente_modelo_de_documento}}/g,
      patient.document_template || ""
    );
    message = message.replace(
      /{{Paciente_observacoes}}/g,
      patient.observations || ""
    );
  }
  return message;
};
const replaceDentistVariable = (message, dentist) => {
  if (dentist) {
    message = message.replace(
      /{{Dentista_inicio_das_ferias}}/g,
      dentist.start_of_vacation || ""
    );
    message = message.replace(
      /{{Dentista_fim_das_ferias}}/g,
      dentist.end_of_vacation || ""
    );
    message = message.replace(/{{Dentista_codigo_dm}}/g, dentist.dm_code || "");
    message = message.replace(/{{Dentista_nome}}/g, dentist.name || "");
    message = message.replace(/{{Dentista_endereco}}/g, dentist.address || "");
    message = message.replace(/{{Dentista_cidade}}/g, dentist.city || "");
    message = message.replace(/{{Dentista_estado}}/g, dentist.state || "");
    message = message.replace(/{{Dentista_cep}}/g, dentist.zip_code || "");
    message = message.replace(/{{Dentista_cpf}}/g, dentist.cpf || "");
    message = message.replace(/{{Dentista_rg}}/g, dentist.rg || "");
    message = message.replace(/{{Dentista_cro}}/g, dentist.cro || "");
    message = message.replace(
      /{{Dentista_commissao}}/g,
      dentist.comission || ""
    );
    message = message.replace(/{{Dentista_email}}/g, dentist.email || "");
    message = message.replace(
      /{{Dentista_telefone}}/g,
      dentist.telephone || ""
    );
    message = message.replace(
      /{{Dentista_data_De_nascimento}}/g,
      dentist.date_of_birth || ""
    );

    return message;
  }

  return message;
};

export { replacePatientVariables, replaceDentistVariable };
