import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, Link } from "react-router-dom";
// Importacoes para a navbar
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PaymentIcon from "@material-ui/icons/Payment";

import FormaPagamentoCadastro from "./components/FormaPagamentoCadastro";
import FormaPagamentoListagem from "./components/FormaPagamentoListagem";
import ContasFinanceirasListagem from "./components/ContasFinanceirasListagem";
import FormCadastroContaFinanceira from "./components/FormCadastroContaFinanceira";
import { Paper } from "@material-ui/core";
import BreadcrumbBar from "../../bars/BreadcrumbBar";

class ConfiguracaoFinanceiroRouter extends Component {
  state = {
    value: 0,
  };

  trocarTab(tab) {
    this.setState({ value: tab });
  }

  render() {
    const { match } = this.props;
    var { value } = this.state;

    const tab = {
      lista: [{ url: "listagem", numero: 0 }],
      tabAtiva: value,
    };
    return (
      <div>
        <BottomNavigation
          className="navbar"
          value={value}
          onChange={(event, newValue) => {
            this.setState({ value: newValue });
          }}
          showLabels
        >
          {/* <BottomNavigationAction
            onClick={() =>
              this.props.history.push(`${match.url}/tiposPagamento`)
            }
            label="Tipos de Pagamento"
          /> */}
          {/* <BottomNavigationAction onClick={() => this.props.history.push(`${match.url}/contas`)} label="Contas Financeiras" /> */}
        </BottomNavigation>
        <Paper style={{ padding: "16px" }}>
          <Switch>
            <Route
              exact
              path={`${match.url}/`}
              render={() => <Redirect to={`${match.url}/tiposPagamento`} />}
            />
            <Route
              exact
              path={`${match.url}/tiposPagamento`}
              render={(props) => (
                <FormaPagamentoListagem
                  {...props}
                  tab={tab}
                  trocarTab={this.trocarTab.bind(this)}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/tiposPagamento/novo`}
              render={(props) => <FormaPagamentoCadastro {...props} />}
            />
            <Route
              exact
              path={`${match.url}/tiposPagamento/:formaPagamento_id?`}
              render={(props) => <FormaPagamentoCadastro {...props} />}
            />
            {/* <Route exact path={`${match.url}/contas`} render={props => <ContasFinanceirasListagem {...props} /> } /> */}
            {/* <Route exact path={`${match.url}/contas/cadastrar`} render={props => <FormCadastroContaFinanceira {...props} /> } /> */}
          </Switch>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectDentist: state.patientsCreation.selectDentist,
  };
}
export default connect(mapStateToProps)(ConfiguracaoFinanceiroRouter);
