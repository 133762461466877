import React, { Component } from "react";
import { connect } from "react-redux";
import TableComponent from "../../../table/TableComponent";
import Edit from "@material-ui/icons/Edit";
import {
  deletarFornecedor,
  buscarFornecedores,
} from "../../../../api/fornecedor";

class FornecedorListagem extends Component {
  state = {
    fornecedores: [],
  };

  deletaFornecedor(objeto) {
    const { clinica } = this.props;
    deletarFornecedor(objeto, clinica._id);
  }

  componentWillMount() {
    buscarFornecedores(this.props.clinica._id).then((fornecedores) => {
      console.log(fornecedores);
      var listaFornecedores = [];
      fornecedores.length > 0 &&
        fornecedores.map((fornecedor, index) => {
          var novo = {};
          novo.codigo = fornecedor.codigo;
          novo.nome = fornecedor.nome;
          novo.fornecedorId = fornecedor._id;
          novo.telefone = fornecedor.telefone;
          novo.contato = fornecedor.contato;
          novo.cnpj = fornecedor.cnpj;
          novo.email = fornecedor.email;
          novo.funcoes = [
            <Edit
              key={index}
              onClick={() =>
                this.props.history.push(
                  `/fornecedor/cadastrarFornecedor/${fornecedor._id}`,
                )
              }
            />,
          ];
          listaFornecedores.push(novo);
        });
      this.setState({ fornecedores: listaFornecedores });
    });
  }
  render() {
    const titulo = "Fornecedores",
      colunas = [
        { label: "Código", name: "codigo" },
        { label: "Nome", name: "nome" },
        { label: "Telefone", name: "telefone" },
        { label: "Contato", name: "contato" },
        { label: "CNPJ", name: "cnpj" },
        { label: "E-Mail", name: "email" },
        { label: " ", name: "funcoes", options: { filter: false, sort: false } },
      ],
      objetos = this.state.fornecedores,
      inserir = {
        nome: "Cadastrar Fornecedor",
        link: "cadastrarFornecedor",
      };
    return (
      <TableComponent
        funcoes={{ deletar: this.deletaFornecedor.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;
  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps)(FornecedorListagem);
