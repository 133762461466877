import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import { css } from "aphrodite/no-important";
import { gridStyles } from "../DashboardStyles";

import ViewNavBar from "../../bars/ViewNavBar";
import Profile from "./Profile";

import ClinicRouter from "./ClinicRouter";
import ConfiguracaoFinanceiroRouter from "../configFinanceiro/ConfiguracaoFinanceiroRouter";
import ModelsRouter from "./ModelsRouter";
import ClinicUsers from "./ClinicUsers";
import UserForm from "../../forms/user/UserForm";
import EndodonticConfigForm from "./EndodonticConfigForm";

const LINKS = [
  { text: "Perfil e Conta", path: "", exact: true },
  { text: "Clínica", path: "/clinic" },
  { text: "Usuários", path: "/users" },
  // { text: 'Listas de Procedimentos', path: '/procedures' },
  { text: "Modelos", path: "/models" },
  // { text: 'Importação', path: '/importing' },
  { text: "Financeiro", path: "/financeiro" },
  { text: "Endodôntica", path: "/endodontic" },
];

// 'ConfigurationRouter' will manage the routes inside the patient module
function ConfigurationRouter(props) {
  const { match, history, user } = props;

  const renderRoutesUserClinic = () => {
    return (
      <>
        <Route path={`${match.url}/clinic`} component={ClinicRouter} />
        <Route path={`${match.url}/users`} component={ClinicUsers} />
        <Route path={`${match.url}/user/:userId`} component={UserForm} />
        {/* <Route path={`${match.url}/procedures`} component={ProcedureRouter} /> */}
        <Route path={`${match.url}/models`} component={ModelsRouter} />
        {/* <Route path={`${match.url}/importing`} component={ImportingRouter} /> */}
        <Route
          path={`${match.url}/financeiro`}
          component={ConfiguracaoFinanceiroRouter}
        />
        <Route
          path={`${match.url}/endodontic`}
          component={EndodonticConfigForm}
        />
      </>
    );
  };

  return (
    <div className={css(gridStyles.flex)}>
      <ViewNavBar history={history} match={match} links={LINKS} />
      {!user?.professional_platform && renderRoutesUserClinic()}
      <Route exact path={`${match.url}`} component={Profile} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth?.user,
  };
};

export default connect(mapStateToProps)(ConfigurationRouter);
