const patient = {
  label: "Paciente",
  name: "Paciente",
  open: false,
  url: "patient",
  suggestions: [
    { label: "Número de Cadastro", value: "numero_cadastro" },
    { label: "Cadastro", value: "cadastro" },
    { label: "Nome", value: "nome" },
    { label: "Sobrenome", value: "sobrenome" },
    { label: "Telefone", value: "telefone" },
    { label: "Situações", value: "situacoes" },
    { label: "Email", value: "email" },
    { label: "Endereço", value: "endereco" },
    { label: "Estado", value: "estado" },
    { label: "Cidade", value: "cidade" },
    { label: "CEP", value: "cep" },
    { label: "Registro", value: "registro" },
    {
      label: "Data do Primeiro Atendimento",
      value: "data_do_primeiro_atendimento",
    },
    { label: "Data de Retorno", value: "data_de_retorno" },
    { label: "Data de Nascimento", value: "data_de_nascimento" },
    { label: "Idade", value: "idade" },
    { label: "Gênero", value: "genero" },
    { label: "Estado Civil", value: "estado_civil" },
    { label: "Profissão", value: "profissao" },
    { label: "RG", value: "rg" },
    { label: "CPF", value: "cpf" },
    { label: "Empresa", value: "empresa" },
    { label: "Pai", value: "pai" },
    { label: "Mãe", value: "mae" },
    { label: "Profissão do Pai", value: "pai_profissao" },
    { label: "Profissão da Mãe", value: "profissao_da_mae" },
    { label: "Convênio", value: "convenio" },
    { label: "Número do Convênio", value: "numero_de_convenio" },
    { label: "Patrocinador", value: "patrocinador" },
    {
      label: "Número do Convênio do Patrocinador",
      value: "numero_do_patrocinador",
    },
    { label: "CPF do Patrocinador", value: "cpf_do_patrocinador" },
    { label: "Indicação", value: "indicacao" },
    { label: "Primeira Consulta", value: "primeira_consulta" },
    { label: "Tempo de Tratamento", value: "tempo_de_tratamento" },
    { label: "Foto", value: "foto" },
    { label: "Status", value: "status" },
    { label: "Ativo", value: "ativo" },
    { label: "Extra", value: "extra" },
    { label: "Prescrição", value: "prescricao" },
    { label: "Pasta", value: "pasta" },
    { label: "Nota Clínica", value: "nota_clinica" },
    { label: "Endodontia", value: "endodontia" },
    { label: "Odontograma", value: "odontograma" },
    { label: "Galeria", value: "galeria" },
    { label: "Arquivos", value: "arquivos" },
    { label: "Anamnese", value: "anamnese" },
    { label: "Modelo de Documento", value: "modelo_de_documento" },
    { label: "Observações", value: "observacoes" },
  ],
};

const dentist = {
  label: "Dentista",
  name: "Dentista",
  open: false,
  url: "dentist",
  suggestions: [
    { label: "ID do Usuário", value: "id_do_usuario" },
    { label: "Agenda", value: "agenda" },
    { label: "Início das Férias", value: "inicio_das_ferias" },
    { label: "Fim das Férias", value: "fim_das_ferias" },
    { label: "Código DM", value: "codigo_dm" },
    { label: "Nome", value: "nome" },
    { label: "Endereço", value: "endereco" },
    { label: "Cidade", value: "cidade" },
    { label: "Estado", value: "estado" },
    { label: "CEP", value: "cep" },
    { label: "CPF", value: "cpf" },
    { label: "RG", value: "rg" },
    { label: "CRO", value: "cro" },
    { label: "Comissão", value: "commissao" },
    { label: "Email", value: "email" },
    { label: "Telefone", value: "telefone" },
    { label: "Data de Nascimento", value: "data_de_nascimento" },
    { label: "Especialidade", value: "especialidade" },
    { label: "Cor", value: "cor" },
    { label: "Ativo", value: "ativo" },
  ],
};

const user = {
  label: "Usuário",
  name: "Usuário",
  open: false,
  url: "user",
  suggestions: [
    { label: "Nome", value: "nome" },
    { label: "Email", value: "email" },
    { label: "Endereço", value: "endereco" },
    { label: "Cidade", value: "cidade" },
    { label: "Estado", value: "estado" },
    { label: "Telefone", value: "telefone" },
    { label: "Clínicas", value: "clinicas" },
    { label: "Plataforma Profissional", value: "plataforma_profissional" },
    { label: "CEP", value: "cep" },
    { label: "Senha", value: "senha" },
    { label: "Ativo", value: "ativo" },
    { label: "Gênero", value: "genero" },
    { label: "Último Login", value: "ultimo_login" },
    { label: "Bloqueado", value: "bloqueado" },
    { label: "Data de Nascimento", value: "data_de_nascimento" },
    { label: "Token", value: "token" },
    { label: "Expiração do Token", value: "expiração_do_token" },
  ],
};

const vendor = {
  label: "Fornecedor",
  name: "Fornecedor",
  open: false,
  url: "vendor",
  suggestions: [
    { label: "Nome", value: "nome" },
    { label: "Endereço", value: "endereço" },
    { label: "Estado", value: "estado" },
    { label: "Cidade", value: "cidade" },
    { label: "Email", value: "email" },
    { label: "Contato", value: "contato" },
    { label: "Website", value: "website" },
    { label: "Telefone", value: "telefone" },
    { label: "Ativo", value: "ativo" },
  ],
};

const receiver = {
  label: "Destinatário",
  name: "Destinatário",
  open: false,
  url: "receiver",
  suggestions: [
    { label: "Nome", value: "nome" },
    { label: "Endereço", value: "endereco" },
    { label: "Estado", value: "estado" },
    { label: "Cidade", value: "cidade" },
    { label: "Email", value: "email" },
    { label: "Telefone", value: "telefone" },
    { label: "Ativo", value: "ativo" },
  ],
};

const prosthetic = {
  label: "Protetico",
  name: "Protetico",
  open: false,
  url: "prosthetic",
  suggestions: [
    { label: "Nome", value: "nome" },
    { label: "Endereço", value: "Endereco" },
    { label: "CEP", value: "cep" },
    { label: "Cidade", value: "cidade" },
    { label: "Estado", value: "estado" },
    { label: "Email", value: "email" },
    { label: "Contato", value: "contato" },
    { label: "Website", value: "website" },
    { label: "Telefone", value: "telefone" },
    { label: "CPF", value: "cpf" },
    { label: "RG", value: "rg" },
    { label: "CRO", value: "cro" },
    { label: "CNPJ", value: "cnpj" },
  ],
};

const treatment = {
  label: "Tratamento",
  name: "Tratamento",
  open: false,
  url: "treatment",
  suggestions: [
    { label: "ID do Paciente", value: "id_do_paciente" },
    { label: "Nome", value: "nome" },
    { label: "Data", value: "data" },
    { label: "Aprovado", value: "aprovado" },
    { label: "Ativo", value: "ativo" },
    { label: "ID da Lista", value: "id_da_lista" },
    { label: "Tratamento", value: "Tratamento" },
    { label: "Orçamentos", value: "Orcamentos" },
    { label: "Dente 18", value: "tooth_18" },
    { label: "Dente 17", value: "tooth_17" },
    { label: "Dente 16", value: "tooth_16" },
    { label: "Dente 15", value: "tooth_15" },
    { label: "Dente 14", value: "tooth_14" },
    { label: "Dente 13", value: "tooth_13" },
    { label: "Dente 12", value: "tooth_12" },
    { label: "Dente 11", value: "tooth_11" },
    { label: "Dente 55", value: "tooth_55" },
    { label: "Dente 54", value: "tooth_54" },
    { label: "Dente 53", value: "tooth_53" },
    { label: "Dente 52", value: "tooth_52" },
    { label: "Dente 51", value: "tooth_51" },
    { label: "Dente 21", value: "tooth_21" },
    { label: "Dente 22", value: "tooth_22" },
    { label: "Dente 23", value: "tooth_23" },
    { label: "Dente 24", value: "tooth_24" },
    { label: "Dente 25", value: "tooth_25" },
    { label: "Dente 26", value: "tooth_26" },
    { label: "Dente 27", value: "tooth_27" },
    { label: "Dente 28", value: "tooth_28" },
    { label: "Dente 61", value: "tooth_61" },
    { label: "Dente 62", value: "tooth_62" },
    { label: "Dente 63", value: "tooth_63" },
    { label: "Dente 64", value: "tooth_64" },
    { label: "Dente 65", value: "tooth_65" },
    { label: "Dente 48", value: "tooth_48" },
    { label: "Dente 47", value: "tooth_47" },
    { label: "Dente 46", value: "tooth_46" },
    { label: "Dente 45", value: "tooth_45" },
    { label: "Dente 44", value: "tooth_44" },
    { label: "Dente 43", value: "tooth_43" },
    { label: "Dente 42", value: "tooth_42" },
    { label: "Dente 41", value: "tooth_41" },
    { label: "Dente 85", value: "tooth_85" },
    { label: "Dente 84", value: "tooth_84" },
    { label: "Dente 83", value: "tooth_83" },
    { label: "Dente 82", value: "tooth_82" },
    { label: "Dente 81", value: "tooth_81" },
    { label: "Dente 31", value: "tooth_31" },
    { label: "Dente 32", value: "tooth_32" },
    { label: "Dente 33", value: "tooth_33" },
    { label: "Dente 34", value: "tooth_34" },
    { label: "Dente 35", value: "tooth_35" },
    { label: "Dente 36", value: "tooth_36" },
    { label: "Dente 37", value: "tooth_37" },
    { label: "Dente 38", value: "tooth_38" },
    { label: "Dente 71", value: "tooth_71" },
    { label: "Dente 72", value: "tooth_72" },
    { label: "Dente 73", value: "tooth_73" },
    { label: "Dente 74", value: "tooth_74" },
    { label: "Dente 75", value: "tooth_75" },
  ],
};
const schedule = {
  label: "Agenda",
  name: "Agenda",
  open: false,
  url: "schedule",
  suggestions: [
    { label: "Nome do Paciente", value: "nome_do_paciente" },
    { label: "Data da Consulta", value: "data_da_consulta" },
    { label: "Hora da Consulta", value: "hora_da_consulta" },
    { label: "atividade", value: "atividade" },
    { label: "evento", value: "evento" },
  ],
};

export const variables = [
  patient,
  dentist,
  user,
  vendor,
  receiver,
  prosthetic,
  treatment,
  schedule,
];
