import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  DialogActions,
  Box,
  Grid,
  Typography,
  Paper,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { listarTabelas } from "../../../api/servicos";
import { buscarDentistas } from "../../../api/dentistas";
import NumberFormat from "react-number-format";
import moment from "moment";
import { formataMoedaReal } from "../../../helpers/Helpers";
import TableComponent from "../../table/TableComponent";
import {
  getMedicalRecord,
  listMedicalRecord,
  editMedicalRecord,
  createMedicalRecord,
} from "../../../api/medical_record";
import { toast } from "react-toastify";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import { facesEnum, teeth } from "../../../utils/teeth";
import { useHistory } from "react-router-dom"; // Importando useHistory
import { filter } from "lodash";

function MedicalRecordPatient({ selectedPatient }) {
  const [rows, setRows] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    medicalRecordId: "",
  });
  const patientId = selectedPatient._id;
  const history = useHistory(); // Criando o hook useHistory

  useEffect(() => {
    const fetchData = async () => {
      await fetchListMedicalRecord();
    };
    fetchData();
  }, []);

  const fetchListMedicalRecord = async () => {
    try {
      const { data } = await listMedicalRecord({ patientId });

      if (!data) return;

      const nextRows = data.data.map((elem) => {
        elem.teeth.text = elem.teeth.map(
          (tooth) => `${tooth.tooth}: ${tooth.faces}`
        );
        return elem;
      });
      setRows(nextRows);
    } catch (error) {
      toast.error("Erro ao obter prontuário");
    }
  };

  const setMedicalRecordDialog = (medicalRecordId) => {
    setDialog({ open: true, medicalRecordId });
  };

  const onCloseDialog = () => {
    setDialog({ open: false });
  };

  const saveMedicalRecord = async (data) => {
    try {
      if (data.medicalRecordId) {
        await editMedicalRecord(data);
      } else {
        data["patient"] = {
          _id: patientId,
        };
        await createMedicalRecord(data);
      }

      await fetchListMedicalRecord();
      toast.success(
        data.medicalRecordId
          ? "Serviço alterado"
          : "Serviço inserido ao prontuário"
      );
      setDialog({ open: false });
    } catch (error) {
      toast.error("Erro ao salvar serviço");
    }
  };

  const columns = [
    {
      label: "Data Execução",
      name: "concludedDate",
      options: {
        customBodyRender: (value) =>
          value ? moment(value).format("DD/MM/YYYY") : "",
      },
    },
    { label: "Dentista", name: "dentist.name" },
    { label: "Serviço", name: "service.description" },
    { label: "Dentes", name: "teeth.text" },
    {
      label: "Valor",
      name: "value",
      options: {
        customBodyRender: (value) => formataMoedaReal(value),
      },
    },
    {
      label: " ",
      name: "_id",
      options: {
        display: "excluded",
        filter: false,
        customBodyRender: (value) => (
          <IconButton
            title="Editar serviço"
            onClick={() => setMedicalRecordDialog(value)}
          >
            <Edit fontSize="small" />
          </IconButton>
        ),
      },
    },
  ];
  const options = {
    elevation: 0,
  };

  return (
    <>
      <Container maxWidth="lg" component={Paper}>
        <Box display="flex" style={{ margin: "16px" }}>
          <Box flexGrow={1}>
            <Typography variant="h5" gutterBottom>
              Prontuário clínico
            </Typography>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              startIcon={<Add />}
              onClick={() => setMedicalRecordDialog("")}
            >
              Adicionar novo
            </Button>
          </Box>
        </Box>
        <TableComponent
          colunas={columns}
          objetos={rows}
          otherOptions={options}
        />
      </Container>
      {dialog.open ? (
        <MedicalRecordFormDialog
          {...dialog}
          onSubmit={saveMedicalRecord}
          onClose={onCloseDialog}
        />
      ) : null}
    </>
  );
}

const getTeeth = (teeth) => {
  return teeth.map((tooth) => parseInt(tooth.tooth));
};

function MedicalRecordFormDialog({ open, medicalRecordId, onSubmit, onClose }) {
  const [serviceTables, setServiceTables] = useState([]);
  const [listDentists, setListDentists] = useState([]);
  const [form, setForm] = useState({
    dentist: "",
    table: "",
    service: "",
    value: "",
    teethSelected: [],
    teeth: [],
    concludedDate: null,
    observation: "",
  });
  const [errors, setErrors] = useState({
    service: false,
    value: false,
    concludedDate: false,
    dentist: false,
  });
  const history = useHistory(); // Criando o hook useHistory

  useEffect(() => {
    const fetchData = async () => {
      const [promise1, promise2, promise3] = await Promise.all([
        medicalRecordId
          ? getMedicalRecord(medicalRecordId)
          : Promise.resolve(null),
        listarTabelas(),
        buscarDentistas(),
      ]);

      if (promise1) {
        const { dentist, service, value, teeth, concludedDate, observation } =
          promise1.data;
        const teethSelected = teeth.length ? getTeeth(teeth) : [];

        setForm({
          table: service.tabelaServico,
          dentist,
          service,
          value,
          teeth,
          concludedDate,
          teethSelected,
          observation,
        });
      }

      setServiceTables(promise2);
      setListDentists(promise3);
    };
    try {
      fetchData();
    } catch (error) {
      toast.error("Erro ao obter dados!");
    }
  }, []);

  const validateForm = () => {
    const errors = {};

    if (!form.service) errors.service = true;
    if (!form.value) errors.value = true;
    if (!form.dentist) errors.dentist = true;
    if (!form.concludedDate) errors.concludedDate = true;

    return errors;
  };

  const handleSubmit = () => {
    const errors = validateForm();

    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }

    const { table, teethSelected, ...rest } = form;

    if (medicalRecordId) rest.medicalRecordId = medicalRecordId;

    onSubmit(rest);
  };

  const changeTeeth = (newValue) => {
    const nextTeeth = newValue.map((tooth) => {
      let faces = [];

      const existTooth = form.teeth.find(
        (elem) => elem.tooth === tooth.toString()
      );

      if (existTooth) faces = existTooth.faces;

      return {
        tooth: tooth.toString(),
        faces,
      };
    });

    setForm({ ...form, teeth: nextTeeth, teethSelected: newValue });
  };

  const changeFaces = (index, newValue) => {
    const nextTeeth = [...form.teeth];
    nextTeeth[index].faces = newValue;
    setForm({ ...form, teeth: nextTeeth });
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>
        {medicalRecordId ? "Editar serviço" : "Novo serviço"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              value={form.table}
              onChange={(_, newValue) => {
                setForm({
                  ...form,
                  table: newValue,
                  service: "",
                });
              }}
              options={serviceTables}
              getOptionLabel={(option) => option.titulo || ""}
              renderOption={(option) => <span>{option.titulo}</span>}
              renderInput={(params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    {...params}
                    label={"Tabela de serviços"}
                    variant="outlined"
                    style={{ flex: 1 }}
                  />
                  <IconButton
                    color="primary"
                    title="Cadastrar tabela de serviços"
                    onClick={() => history.push("/servicos")}
                  >
                    <Add />
                  </IconButton>
                </div>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              value={form.service}
              onChange={(_, newValue) =>
                setForm({
                  ...form,
                  service: newValue,
                  value: newValue?.finalValue || "",
                })
              }
              options={form.table?.servicos || []}
              getOptionLabel={(option) => option.description || ""}
              renderOption={(option) => <span>{option.description}</span>}
              renderInput={(params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    {...params}
                    label={"Serviço"}
                    error={errors.service}
                    variant="outlined"
                    style={{ flex: 1 }}
                  />
                  <IconButton
                    onClick={() =>
                      form.table &&
                      history.push(`/servicos/${form.table._id}/servicos`)
                    }
                    color="primary"
                    title="Cadastrar serviço"
                    disabled={!form.table}
                  >
                    <Add />
                  </IconButton>
                </div>
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              multiple
              value={form.teethSelected}
              options={teeth}
              onChange={(_, newValue) => {
                changeTeeth(newValue);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} label={"Dentes"} variant="outlined" />
              )}
            />
          </Grid>
          {form.teeth.length
            ? form.teeth.map((tooth, index) => (
                <Grid item key={tooth.tooth}>
                  <Autocomplete
                    multiple
                    value={tooth.faces}
                    options={facesEnum}
                    onChange={(_, newValue) => {
                      changeFaces(index, newValue);
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Faces: ${tooth.tooth}`}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              ))
            : null}
          <Grid item xs={12}>
            <Autocomplete
              value={form.dentist}
              onChange={(_, newValue) =>
                setForm({
                  ...form,
                  dentist: newValue,
                })
              }
              options={listDentists}
              getOptionLabel={(option) => option.name || ""}
              renderOption={(option) => <span>{option.name}</span>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Dentista"}
                  error={errors.dentist}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberFormat
              label="Valor"
              customInput={TextField}
              prefix="R$"
              value={form.value}
              onValueChange={(e) => setForm({ ...form, value: e.floatValue })}
              thousandSeparator="."
              decimalSeparator=","
              variant="outlined"
              fullWidth
              error={errors.value}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                fullWidth
                label="Data de execução"
                format="dd/MM/yyyy"
                value={form.concludedDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(date) => {
                  setForm({ ...form, concludedDate: date });
                }}
                inputVariant="outlined"
                error={errors.concludedDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Observação"
              fullWidth
              variant="outlined"
              value={form.observation}
              onChange={(e) =>
                setForm({ ...form, observation: e.target.value })
              }
              type="text"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          color="primary"
          disableElevation
          variant="contained"
          onClick={handleSubmit}
        >
          {medicalRecordId ? "Salvar" : "Inserir"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedPatient: state.patientsCreation.selectedPatient,
  };
};

export default connect(mapStateToProps, {})(MedicalRecordPatient);
