import React, { useState, useEffect, useSearchParams } from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";
import {
  buscarTodosProcedimentos,
  alterarConclusaoProcedimento,
} from "../../../../api/tratamentos";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core";
import { AssignmentTurnedIn, Undo } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

export default function ProcedimentosDentistas(props) {
  const [listaProcedimentos, setListaProcedimentos] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [dialogUndo, setDialogUndo] = useState(false);
  const [procedimento, setProcedimento] = useState({
    dataConclusao: new Date(),
    error: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await buscarTodosProcedimentos();
    };
    fetchData();
  }, []);

  const buscarProcedimentos = async () => {
    const procedimentos = await buscarTodosProcedimentos();
    filtrarDentistaId(procedimentos, (procedimento) => {
      if (!procedimento) {
        return;
      }
      procedimento.procedures.map((procedure) => {
        procedure.funcoes = {
          id: procedure.procedureId,
          status: procedure.procedureStatus,
        };
      });
      setListaProcedimentos(procedimento.procedures);
    });
  };

  const filtrarDentistaId = (listaProcedimentos, callback) => {
    const id = props.match.params.dentista_id;
    const procedimentosDentistaId = listaProcedimentos.find(
      (procedimento) => procedimento.dentist._id === id,
    );
    callback(procedimentosDentistaId);
  };

  const handleChangeConclusaoProcedimento = async () => {
    if (dialog && !procedimento.dataConclusao) {
      return setProcedimento({ ...procedimento, error: true });
    }
    await alterarConclusaoProcedimento(procedimento);
    buscarProcedimentos();
    setDialog(false);
    setDialogUndo(false);
  };

  const openDialog = (id) => {
    setProcedimento({ ...procedimento, id, concluido: true });
    setDialog(true);
  };

  const openDialogUndo = (id) => {
    setProcedimento({ dataConclusao: null, id, concluido: false });
    setDialogUndo(true);
  };

  const colunas = [
    {
      label: "Data",
      name: "procedureDate",
      options: {
        customBodyRender: (value) => {
          return moment(value).format("DD/MM/YYYY");
        },
      },
    },
    { label: "Paciente", name: "patientName" },
    { label: "Procedimento", name: "procedureName" },
    { label: "Tratamento", name: "treatmentName" },
    { label: "Orçamento", name: "budgetName" },
    {
      label: "Status",
      name: "procedureStatus",
      options: {
        customBodyRender: (value) => (value ? "Concluído" : "Pendente"),
      },
    },
    {
      label: "Data conclusão",
      name: "procedureDataConclusao",
      options: {
        customBodyRender: (value) =>
          value && moment(value).format("DD/MM/YYYY"),
      },
    },
    {
      label: " ",
      name: "funcoes",
      options: {
        filter: false,
        customBodyRender: (value) =>
          !value.status ? (
            <IconButton onClick={() => openDialog(value.id)}>
              <AssignmentTurnedIn />
            </IconButton>
          ) : (
            <IconButton onClick={() => openDialogUndo(value.id)}>
              <Undo />
            </IconButton>
          ),
      },
    },
  ];

  return (
    <>
      <MUIDataTable
        title={"Procedimentos"}
        columns={colunas}
        data={listaProcedimentos}
      />
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogTitle>Concluir procedimento?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Informe a data de conclusão do procedimento
          </DialogContentText>
          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
            <KeyboardDatePicker
              error={procedimento.error}
              helperText={procedimento.error ? "Campo vazio" : ""}
              fullWidth
              format="dd/MM/yyyy"
              size="small"
              value={procedimento.dataConclusao}
              onChange={(e) =>
                setProcedimento({ ...procedimento, dataConclusao: e })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleChangeConclusaoProcedimento}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogUndo} onClose={() => setDialogUndo(false)}>
        <DialogTitle>Remover conclusão?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que desja remover a conclusão deste procedimento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleChangeConclusaoProcedimento}>
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
