import React, { Component } from "react";
import { connect } from "react-redux";
import {
  deletarEspecialidade,
  buscarEspecialidades,
} from "../../../../api/dentistas";
import { editDentist } from "../../../../actions/dentistas/dentistSearch";
import { toast } from "react-toastify";

import TableComponent from "../../../table/TableComponent";

//Icone de arquivar
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

class DentistasConfigEspecialidade extends Component {
  state = {
    especialidades: [],
  };

  async deletaEspecialidade(especialidadeId) {
    const especialidade = await deletarEspecialidade(especialidadeId);
    console.log(especialidade);
    if (especialidade) {
      toast.success("Especialidade excluída com sucesso");
      this.findEspecialidade();
      return;
    }
    toast.error("Erro ao excluir especialidade");
  }

  findEspecialidade() {
    buscarEspecialidades(this.props.clinica._id).then((especialidades) => {
      var listaEspecialidades = [];

      especialidades.length > 0 &&
        especialidades.map((especialidade, index) => {
          var novaEspecialidade = {};

          novaEspecialidade.titulo = especialidade.titulo;
          novaEspecialidade.especialidadeId = especialidade._id;
          novaEspecialidade.funcoes = [
            <Edit
              key={index}
              onClick={() =>
                this.props.history.push(
                  `/dentistas/config/cadastrarEspecialidade/${especialidade._id}`
                )
              }
            />,
            <DeleteIcon
              key={`delete-${index}`}
              onClick={() => this.deletaEspecialidade(especialidade._id)}
              style={{ cursor: "pointer" }}
            />,
          ];
          listaEspecialidades.push(novaEspecialidade);
        });

      this.setState({ especialidades: listaEspecialidades });
    });
  }

  componentWillMount() {
    this.findEspecialidade();
  }

  render() {
    const titulo = "Especialidades",
      colunas = [
        { label: "Titulo", name: "titulo" },
        { label: " ", name: "funcoes", options: { filter: false, sort: false } },
      ],
      objetos = this.state.especialidades,
      inserir = {
        nome: "Cadastrar Especialidade",
        link: "cadastrarEspecialidade",
      };

    return (
      <TableComponent
        funcoes={{ deletar: this.deletaEspecialidade.bind(this) }}
        titulo={titulo}
        inserir={inserir}
        colunas={colunas}
        objetos={objetos}
        {...this.props}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    user: auth.user,
    clinica: auth.clinic,
  };
}
export default connect(mapStateToProps, { editDentist })(
  DentistasConfigEspecialidade
);
